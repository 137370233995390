










































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'c-date-picker',
    filters: {
        dateFormat: function (value: string) {
            if (!value) return ''
            value = value.toString()
            const dateArr = value.split("-")
            const dateNewOrder = []
            for (let i = 2; i >= 0; i--) {
                if (dateArr[i]) {
                    dateNewOrder.push(dateArr[i])
                }
            }
        return dateNewOrder.join('.')
        }
    }
})

export default class CDatePicker extends Vue {
    @Prop({
        required: true,
    })
    private item!: any;

    @Prop({
        required: true,
    })
    private header!: any;

    @Prop({
        required: true,
        default: 'start'
    })
    private startEnd!: 'start' | 'end';

    @Prop({
        required: true,
    })
    private dateField!: 'beg_date' | 'end_date';

    private mounted(): void {
        this.setCalendarBtnStyle('#7c9db3cc')
    }

    // ----------- Получение крайних дат -------------------
    private get minDate(): string {
        let minDate = ''
        if (this.header && this.header.year) {
            const year = `${this.header.year}-01-01`
            if (this.startEnd === 'end') {
                if (this.item && this.item.beg_date) {
                    let begDatePlusDay = this.item.beg_date
                    if (begDatePlusDay) {
                        const endDay = parseInt(begDatePlusDay.slice(-2));
                        const nextDay = endDay + 1;
                        begDatePlusDay =  begDatePlusDay.slice(0, -2) + this.addZero(nextDay);
                    }
                    minDate = begDatePlusDay > year ? begDatePlusDay : year;
                }
            }
        }
        return minDate
    }

    private get maxDate(): string {
        let maxDate = ''
        if (this.header && this.header.year) {
            const year = `${this.header.year}-12-31`
            if (this.startEnd === 'start') {
                maxDate = year
                if (this.item.end_date) {
                    let endDateMinusDay = this.item.end_date
                    if (endDateMinusDay) {
                        const endDay = parseInt(endDateMinusDay.slice(-2));
                        const prevDay = endDay - 1;
                        endDateMinusDay =  endDateMinusDay.slice(0, -2) + this.addZero(prevDay);
                    }
                    maxDate = endDateMinusDay < year ? endDateMinusDay : year;
                }
            }
        }
        return maxDate
    }

    private addZero(number: number) {
        if (number < 10) return '0' + number;
        return '' + number;
    }


    // ----------- Стилизация компонента -------------------
    private onOpenCalendar(): void {
        this.$nextTick(() => {
            this.setCalendarBackgroundColor('white')
        })
    }

    private setCalendarBackgroundColor(color: string): void {
        const calendar: HTMLElement | null = document.querySelector('.calc-forms-date-picker .b-calendar-grid')
        if (calendar) {
            calendar.style.backgroundColor = color;
        }
    }

    private setCalendarBtnStyle(color: string): void {
        const btnArr: HTMLElement[] = Array.from(document.querySelectorAll('.table-container .table .calc-forms-date-picker button.btn.btn-secondary'))
        if (btnArr) {
            btnArr.forEach((btn: HTMLElement, i: number) => {
                btn.style.setProperty("background-color", color, "important");
                btn.style.padding = '0 4px';
            })
        }
    }
}

